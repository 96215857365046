import firebase from "firebase";
import Moods from "@/lib/const";
import firebaseConfig from '@/lib/credentials';
import {AdminItems} from '@/interface/AdminItems'


const actions = {
    doAuthCheck({ commit }: any) {
        return new Promise((resolve) => {
            // Setup Firebase onAuthStateChanged handler
            // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#onAuthStateChanged
            firebase.auth().onAuthStateChanged(user => {
                commit("authChecked", {authChecked: true})
                if (user) {
                    const newUser = {
                        id: user.uid,
                        name: user.displayName,
                        email: user.email
                    }
                    firebase.database().ref(`/roles/${user.uid}`).once('value').then(snapshot => {
                        const role = snapshot.val()
                        if (role && role.type === Moods.TYPE_ADMIN) {
                            commit("userLoginSuccess", {user: { ...newUser }})
                            resolve(newUser)
                        } else {
                            firebase.auth().signOut();
                            resolve(false)
                        }
                    });
                } else {
                    commit("authError", null)
                    commit("authChecked", {authChecked: true})
                    resolve(null)
                }
            })
        }).catch(cc => {
            console.log(cc)
            commit("authError", cc)
        });
    },

    /**
     *
     * @param {*} param0
     */
    userLogout({ commit }: any) {
        commit("setLoading", true)
        commit("authError", {error: null});
        // start the request...
        commit("userLogoutRequest")
        // MAKE API CALL
        return firebase
            .auth()
            .signOut()
            .then(user => {
                // when successful...
                commit("userLogoutSuccess", user)
                return true
            })
            .catch(err => {
                console.log(err)
                commit("authError", err)
                return false
            })
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    resetPassword({ commit }: any, payload: any) {
        commit("setLoading", true)
        commit("authError", {error: null});
        // MAKE API CALL
        return firebase
            .auth()
            .sendPasswordResetEmail(payload.email)
            .then(() => {
                return true;
            })
            .catch(err => {
                console.log(err)
                commit("authError", err)
                return false
            })
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    userLogin({ commit }: any, payload: any) {
        return new Promise((resolve) => {
            // MAKE API CALL
            firebase .auth()
                .signInWithEmailAndPassword(payload.email, payload.password)
                .then(tmp => {
                    // when successful...
                    const user = firebase.auth().currentUser;
                    if (user) {
                        firebase.database().ref(`/roles/${user.uid}`).once('value').then(snapshot => {
                            const role = snapshot.val()
                            if (role && role.type === Moods.TYPE_ADMIN) {
                                commit("userLoginSuccess", tmp)
                                resolve(true)
                            } else {
                                commit("authError", {message: 'Permission Not Allowed'})
                                firebase.auth().signOut();
                                resolve(false)
                            }
                        });
                    } else {
                        commit("authError", {message: 'User Not Found (3)'})
                        resolve(false)
                    }
                });
        });
    },
    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    async createAccount({ commit, dispatch }: any, payload: any) {
        const firstApp = firebase.database();
        const mainUser = firebase.auth().currentUser;
        if (firstApp && mainUser) {
            commit("setLoading", true)
            // add new connection to firebase, cz firebase auto login new users
            const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary" + Math.random());
            await secondaryApp.auth().createUserWithEmailAndPassword(payload.email, payload.password);
            const newUser = secondaryApp.auth().currentUser;
            if (newUser && mainUser) {
                await firebase.database().ref(`/ownerProfile/${newUser.uid}/email`).set(payload.email);
                await firebase.database().ref(`/roles/${newUser.uid}/type`).set(Moods.TYPE_OWNER);
                dispatch('getAdminList');
            } else {
                commit("setLoading", false)
            }
        }
    },
    getAdminList({ commit }: any) {
        commit("setLoading", true)
        firebase.database().ref(`/ownerProfile`).orderByChild("email").on('value', snapshot => {
            const adminList: AdminItems[] = [];
            snapshot.forEach(snap => {
                adminList.push({
                    id: snap.key,
                    email: snap.val().email
                } as AdminItems);
                if (adminList.length === snapshot.numChildren()) {
                    commit('setList', adminList);
                    commit("setLoading", false)
                }
                return false;
            });
        });
    }
};

export default actions;