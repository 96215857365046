
import { IonPage, IonIcon, IonButtons, IonHeader, IonToolbar, IonTitle, IonContent, IonInput } from '@ionic/vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import { onIonViewWillEnter } from '@ionic/vue';
import { chevronBackOutline, personOutline } from "ionicons/icons";
import LogoContainer from '@/components/LogoContainer.vue';
import libStore from "@/lib/libStore";
import openToast from "@/lib/defaultToast";

export default defineComponent({
  name: 'ResetPassword',
  components: {
    IonButtons,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonPage,
    IonGrid,
    IonInput,
    IonRow,
    IonCol,
    LogoContainer
  },
  setup () {
    const { goToHome, goToLogin, authErrorMessage, store } = libStore();
    onIonViewWillEnter(() => {
      goToHome();
    });
    return {
      store,
      goToLogin,
      authErrorMessage,
      chevronBackOutline,
      personOutline
    }
  },
  data() {
    return {
      email: null
    }
  },
  methods: {
    async doReset() {
      try {
        const isSuccess = await this.store.dispatch('user/resetPassword', {email: this.email});
        if (isSuccess === false) {
          await openToast(this.authErrorMessage());
        } else {
          await openToast("Email Reset Sent Successfully");
          this.goToLogin();
        }
      } catch (e) {
        console.log(e);
        await openToast(this.authErrorMessage(e));
      }
    }
  }
});
