
const getters = {
    loggedIn: (state: { user: any }) => {
        return !!state.user
    },
    authError: (state: { error: any }) => {
        return state.error
    },
    currentUser: (state: { user: any }) => {
        return state.user
    },
    adminList: (state: { list: any }) => {
        return state.list
    },
    loading: (state: { loading: any }) => {
        return state.loading
    }
};

export default getters;