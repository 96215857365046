
const state = {
    list: [],
    isAuthenticated: false,
    loading: false,
    error: null,
    user: null,
    authChecked : false
};

export default state