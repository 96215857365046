export default {
    // PROD
    apiKey: "AIzaSyCaLJQgDG_bmH9cUPYoMko_th_42wmeAZA",
    authDomain: "happinessbank-8f918.firebaseapp.com",
    databaseURL: "https://happinessbank-8f918.firebaseio.com",
    projectId: "happinessbank-8f918",
    storageBucket: "",
    messagingSenderId: "182223425726"
    // DEV
    // apiKey: "AIzaSyDSPuapyr-oWiCYnke0LC7Rfbzfxi2ewX8",
    // authDomain: "oliverapp-a39f9.firebaseapp.com",
    // databaseURL: "https://oliverapp-a39f9.firebaseio.com",
    // projectId: "oliverapp-a39f9",
    // storageBucket: "oliverapp-a39f9.appspot.com",
    // messagingSenderId: "445146099705"
}