import { createStore } from 'vuex'
import user from './modules/user'
import firebase from "firebase/app";
import "firebase/firestore";
import firebaseConfig from '@/lib/credentials';

firebase.initializeApp(firebaseConfig);

const store = createStore({
    modules: {
        user
    },
})

export default store
